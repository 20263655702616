<template>
    <div class="learnCenter">
        <img src="" alt="">
        <div class=""></div>
        <div class="">
            <div class="title">
                提供多种免费学习工具，祝你快速成长！
            </div>
            <div class="">
                <div class="left">
                    <div class="item">
                        <span></span>
                        <span></span>
                    </div>
                    <div class="item">
                        <span></span>
                        <span></span>
                    </div>
                    <div class="item">
                        <span></span>
                        <span></span>
                    </div>
                    <div class="item">
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div class="right">
                    <img src="" alt="">
                </div>
            </div>
        </div>
        <div class="">
            <div class="title">最近学习</div>
            <div class="list">
                <div class="item">
                    <div class="left">
                        <div class="img">
                            <img src="" alt="">
                        </div>
                        <div class="content">
                            <p>中级零基础《初级会计实务》会计科目及借贷记法</p>
                            <p>
                                <span>2021-07-06 10:50</span>
                                <span>学习至2021年初级会计实务备考指导</span>
                            </p>
                            <p>
                                <el-progress :percentage="50" show-text="false"></el-progress>
                                <span>已学习：2/4课时</span>
                            </p>
                            
                        </div>
                    </div>
                    <div class="right">
                        <button>继续学习</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'learnCenter'
}
</script>

<style>

</style>